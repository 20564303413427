.imageComponent {
  position: relative;
  height: 88%;
  max-height: 100%;
}

.imageComponent > img {
    border-radius: 10px;
}

.gallerySliderComponent {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.gallerySliderScrollComponent {
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;
  scroll-behavior: smooth;
  margin-top: 5px;
}

.gallerySliderScrollComponent::-webkit-scrollbar {
  display: block;
  width: 4px;
  height: 4px;
  background-color: var(--color-dark-green);
  border-radius: 20px;
}

.gallerySliderScrollComponent::-webkit-scrollbar-thumb {
  background-image: var(--color-background-linear-gradient-color);
  border-radius: 20px;
}

.selectedGallerySliderCardComponent {
  width: 100px;
  height: 50px;
  padding: 3px;
  border-radius: 10px;
  flex-grow: 1;
  overflow: hidden;
  display: inline-block;
  background: var(--color-background-linear-gradient-color);
  cursor: pointer;
}

.gallerySliderCardComponent {
  width: 100px;
  height: 50px;
  padding: 3px;
  cursor: pointer;
  flex-grow: 1;
  overflow: hidden;
  display: inline-block;
}

.gallerySliderCardComponent > img {
  border-radius: 10px;
}

.selectedGallerySliderCardComponent > img {
  border-radius: 10px;
}

.PrevIcon {
  position: absolute;
  /* left: 10%; */
  top: 50%;
  left: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: var(--color-background-linear-gradient-color);
  border-radius: 100px;
  padding: 5px;
  z-index: 1;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.nextIcon {
  position: absolute;
  top: 50%;
  right: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: var(--color-background-linear-gradient-color);
  border-radius: 100px;
  padding: 5px;
  z-index: 1;
  cursor: pointer;
}

:is(.nextIcon:active, .PrevIcon:active) {
  scale: 0.8;
}
