/* Appointment.module.css */
.mainContainer{
    display: flex;
    width: 80%;
    align-items: baseline;
    justify-content: center;
    margin: 5% auto;
}

.container{
    display: flex;
    width: 35%;
    flex-direction: column;
    
}
.contentContainer {
    margin: 0 auto;
    width: 65%;
    
  }
 .mainHeading{
    display: flex;
    align-items: flex-start;
    justify-content: center;

 }
  
  .heading {
    
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: #333; /* Replace with your desired color */
    margin-bottom: 5%;
  }
  .innerDivider {
    height: 20px;
    margin-top: 10px;
}
  .headingAppointment{
    margin-top: 5%;
  }
  .headingone{
    font-size: 2.5rem;
    color: var(--color-dark-green);
    line-height: 1.2em;
    letter-spacing: 1px;
    text-align: center;
    margin: 0;
  }
  .headingtwo{
    background-image: linear-gradient( to right, var(--color-lightblue), var(--color-green) );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  /* Header styles */
  .header {
    border-bottom: 1px solid #ccc;
    background-color: #fff;
    padding-bottom: 1rem;
  }
  
  /* Main content styles */
  .main {
    margin: 0 auto;
    max-width: 100%;
    padding: 1rem;
  }
  
  .grid {
   width: 90%;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  
  /* Form styles */
  .form {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  
  .formLabel {
    font-weight: 700;
    color: var(--color-dark-green);
    line-height: 1.2em;
    margin: 2%;
    animation: var(--global-line-swipe) 5s ease;
  }
  .select{
    margin: 3%;
    font-weight: 700;
    color: var(--color-dark-green);
    line-height: 1.2em;
  }
  .selectInput{
    flex: 1;
    height: 2.5rem;
    width: 50%;
    border: 1px solid #ccc;
    background-color: transparent;
    padding: 0.5rem;
    font-size: 0.875rem;
    color: #333;
    margin: 2%;
    font-weight: 700;
    color: var(--color-dark-green);
    line-height: 1.2em;

  }
  
  .formInput {
    flex: 1;
    height: 1.5rem;
    width: 90%;
    border: 1px solid #ccc;
    background-color: transparent;
    padding: 0.5rem;
    font-size: 0.875rem;
    color: #333;
    margin: 2%;
  }
  .grid2 {
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 1rem;
  }
  .formTextarea {
    flex: 1;
    height: 3.5rem;
    width: 90%;
    border: 1px solid #ccc;
    background-color: transparent;
    padding: 0.5rem;
    font-size: 0.875rem;
    color: #333;
    margin: 2%;
  }
  .formLabelSpan{
    font-weight: 800;
    color: var(--color-dark-green);
    line-height: 1.2em;
    
    animation: var(--global-line-swipe) 5s ease;
    margin-right: 2%;

  }
  .formLabelService{
    font-weight: 700;
    color: #26C4BA;
    line-height: 1.2em;
    margin: 2%;
    animation: var(--global-line-swipe) 5s ease;
  }
  
  .form-button {
    width: 100%;
    border: none;
    background-color: #000;
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .form-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  /* Footer styles */
  .footer {
    margin-top: 1rem;
    border-top: 1px solid #ccc;
  }
  .submitButton {
    padding: 2.3% 24%;
    border-radius: 100px;
    min-width: max-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    font-weight: 400;
    margin-top: 8%;
    font-size: 20px;
    border: 1px solid rgba(0, 0, 0, 0.8);
    background: var(--color-background-linear-gradient-color);
}  


@media screen and (max-width: 767px) {
.mainContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
   
}
.container{
      width: 100%;
   }
  .contentContainer {
    margin: 0 auto;
    width: 100%;
    
  }
}
@media screen and (max-width: 580px) {
  .grid {
    width: 95%;
    grid-template-columns: none;
    
   }
   .form {  
    grid-template-columns: none;
      }
      .grid2 {
        
        grid-template-columns: none;
       
      }
      .formTextarea {
       
        width: 90%;
       
      }
      .selectInput{
        width: 45%;
      }
}


