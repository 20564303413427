.footerRootComponent {
  display: flex;
  justify-content: center;
}

.footer_lastTwo_root_component {
  background-color: var(--color-light-green);
}

.footer_top_component {
  background-color: var(--color-dark-green); /* add back image */
  padding: 2%;
  position: relative;
}

.footer_overlay {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: #1a1a1a;
  opacity: 0.3;
}

.footer_top_text_component {
  display: flex;
  gap: 30px;
  align-items: center;
}

/* .footer_top_text_component:is( div:nth-child(1), div:nth-child(2)) {
    width: 50%;
} */

.footer_top_text_component > div:nth-child(1) {
  flex: 0.8;
}

.footer_bottom_input_component {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer_top_text_component_heading {
  color: var(--color-white);
  line-height: 1.2em;
  letter-spacing: 1px;
  font-weight: 700;
}

.footerInputComponent {
  border-bottom: 1px solid var(--color-white);
  width: 20rem;
  padding: 5px;
}

.footerInputComponent > input {
  outline: none;
  width: 100%;
  border: none;
  background-color: var(--color-dark-green);
}

.footerInputComponent > input::placeholder {
  color: var(--color-white);
}

.footer_subscribe_btn {
  padding: 1% 40%;
  /* width: 300px; */
  color: var(--color-white);
  background-image: var(--color-background-linear-gradient-color);
  /* animation: var(--global-zoom-in-out) 5s ease;s */
  border-radius: 100px;
  min-width: max-content;
  max-width: fit-content;
  font-weight: 600;
  margin-top: 12%;
  font-size: 20px;
}

.bottom_footer_component {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.companyProfileLogoComponent {
  display: flex;
  align-items: center;
  gap: 5px;
}

.companyProfileLogoComponent > p {
  font-size: 20px;
  line-height: 1.2em;
  letter-spacing: 1px;
  font-weight: 600;
  color: var(--color-dark-green);
}

.companyProfileLogo {
  width: 100px;
  height: 100px;
}

.companyProfileLogo > img {
  filter: drop-shadow(0 3px 10px rgb(0 0 0 / 0.2));
}

.icon_component {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 3% 0 0 10%;
}

.icon_component > a {
  margin: 0;
  padding: 0;
  outline: none;
  font-size: 1rem;
  text-decoration: none;
  color: var(--color-dark-green);
  font-weight: 600;
}

.icon_component > a:hover {
  color: var(--color-green);
}

.bottom_footer_component {
  padding: 2% 0;
}

.footer_gallery_component {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.footer_bottom_gallery_component {
  flex: 0.3;
  margin-top: 2%;
}

.footer_services_component {
  margin-top: 2%;
}

.footer_copyRightComponent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 1px solid var(--color-java);
}

.footer_social_icon_component {
  display: flex;
  gap: 10px;
  align-items: center;
}

.footer_social_individual_icon_component {
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-dark-green);
  border-radius: 100px;
  cursor: pointer;
}

.footer_social_individual_icon_component:hover {
  background-color: var(--color-green);
}

.footer_services_component > h3,
.footer_bottom_gallery_component > h3 {
  font-weight: 600;
  color: var(--color-dark-green);
  letter-spacing: 1px;
  line-height: 1.2em;
  margin: 0;
  text-align: center;
}

.footer_services_component > :is(a, a:active, a:focus) {
  outline: none;
  color: var(--color-dark-green);
  text-decoration: none;
  font-weight: 600;
  position: relative;
}

.footer_services_component > a::after {
  content: "";
  background-color: var(--color-green);
  width: 0%;
  height: 3px;
  position: absolute;
  bottom: -5px;
  left: 0;
  transition: all 0.5s ease-in-out;
}

.footer_services_component > a:hover::after {
  content: "";
  width: 100%;
}

.footer_services_component > a:hover {
  color: var(--color-green);
}

.footer_services_component {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media screen and (max-width: 980px) {
  .bottom_footer_component {
    gap: 40px;
  }
  .footer_top_text_component {
    flex-direction: column;
    text-align: center;
  }

  .footer_bottom_input_component {
    justify-content: center;
    gap: 20px;
    width: 100%;
  }
  .footerInputComponent {
    width: 100%;
  }

  .footer_subscribe_btn {
    padding: 1% 30%;
  }
  .footer_bottom_gallery_component {
    flex: 1;
    margin-right: 0;
  }
}
