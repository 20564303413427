.slideOneComponent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.slideOneInnerComponent {
  display: flex;
  margin: 0 auto;
}

.slideOneTitleComponent {
  flex: 0.4;
}

.slideOneSubIntro {
  color: var(--color-gray);
}

.slideOneImageComponent {
  flex: 0.6;
}

.slideOneTitle {
  font: 700 4rem "Fira Sans", sans-serif;
  animation: var(--global-line-swipe) 1s ease-out;
}

.slideOneInnerOuterComponent {
  display: flex;
  justify-content: flex-end;
}
.slideOneImageInnerComponent {
  background-image: var(--slide-two-image);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 40rem;
  width: 40rem;
  animation: var(--global-zoom-in-out) 0.5s ease;
  -webkit-mask-image: url("/public/images/blob-3-min.png");
  -webkit-mask-size: contain;
  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
}

.slideTwoTitleComponent {
  flex: 0.6;
  background-image: url("/public/images/Ornament-1-a.png");
  background-position: left left;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  margin-top: 17%;
}
.slideOneBtn {
  text-decoration: none;
  color: #000;
}

.slideOneBtnIcon {
  rotate: 180deg;
}

.slideOneBtnIconComponent {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font: 500 30px sans-serif;
  max-width: 10rem;
  margin-top: 30px;
  border-radius: 100px;
  background: linear-gradient(
    to right,
    var(--color-lightblue),
    var(--color-green)
  );
}

@media screen and (max-width: 600px) {
  .slideOneInnerComponent {
    display: flex;
    flex-direction: column;
   
  }

  .slideOneComponent {
    margin-top: 4%;
  }

  .slideOneTitle {
    font: 700 2rem "Fira Sans", sans-serif;
  }
}
