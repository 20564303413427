.headerRootComponent {
  background-image: url("/public/images/img-mandala-01-min.png");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 495px auto;
  opacity: 0.7;
  height: 100%;
  width: 100%;
  mix-blend-mode: difference;
}

.headerComponent {
  height: 45vh;
  padding: 1% 10% 0 10%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  background: linear-gradient(180deg, #f4fff8 0, var(--color-light-green) 100%);
}

.routesLinksComponent {
  display: flex;
  align-items: center;

  gap: 4px;
}

.routesLinksComponent > li,
.routesLinksComponent > li > a {
  text-decoration: none;
  list-style: none;
  font-weight: 600;
  font-size: 20px;
  outline: none;
  color: var(--color-dark-green);
}
.routesLinksComponent > li > a:focus {
  outline: none;
}

.notFoundText {
  font-size: 2.5rem;
  color: var(--color-dark-green);
  margin: 0 0 0 5%;
}

.introRootComponent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 5% auto;
}

.text404 {
  font-size: 9em;
  color: var(--color-dark-green);
  margin: 0;
}

.failureText {
  font-family: sans-serif;
  color: var(--color-dark-green);
  line-height: 1.2em;
  font-size: 2em;
}

.innerDivider {
  height: 30px;
}

.homeButton {
  color: var(--color-dark-green);
  font-size: 20px;
  font-weight: 500;
  padding: 0.5% 2%;
  border-radius: 100px;
  min-width: max-content;
  max-width: 3rem;
  margin: 0 auto;
}

.homeButton:hover {
  color: var(--color-white);
  animation: var(--global-zoom-in-out) 0.5s ease;
}

@media screen and (max-width: 800px) {
  .headerComponent {
    flex-direction: column-reverse;
    padding: 0;
  }

  .notFoundText {
    margin-left: 8%;
  }

  .headerRootComponent {
    background-size: 300px auto;
  }

  .introRootComponent {
    padding: 10px;
  }

  .homeButton {
    padding: 0.5% 6%;
  }
}
