.navCommonComponentStyle {
  background-color: var(--color-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navWebComponent {
  composes: navCommonComponentStyle;
  padding: 10px 15%;
}

.navMobileComponent {
  composes: navCommonComponentStyle;
  padding: 7px 5%;
}

.navLogoComponent {
  width: 100px;
  height: 100px;
}

.flexProperty {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navLinksComponent {
  composes: flexProperty;
  gap: 15px;
  flex: 0.6;
}

.navMobileLinksComponent {
  composes: flexProperty;
  flex-direction: column;
}

.navMobileMenuDropdown {
  position: absolute;
  top: 14%;
  left: 0;
  flex: 1;
  width: 100%;
  z-index: 1;
  background-color: var(--color-white);
  animation: growDown 300ms ease-in-out forwards;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

.navMobileLinkCommonStyle {
  font-size: 1rem;
  font-weight: 600;
  font-family: var(--e-global-typography-accent-font-family), sans-serif;
  outline: none;
  text-align: center;
  color: var(--color-black);
}

.navMobileActiveCommonStyle {
  color: var(--color-white);
}


.navMobileActiveLinkOuterComponent {
  background:  linear-gradient(to right, var(--color-lightblue), var(--color-green));
  padding: 10px;
  text-align: center;
}

.linkInActiveOuterComponent {
  text-align: center;
  padding: 10px;
  /* border-bottom: 0.1px solid var(--color-dark-green); */
}

.linkComponent {
  min-width: max-content;
}

.navLinkCommonStyle {
  font-size: 1rem;
  color: var(--color-black);
  font-weight: 600;
  font-family: var(--e-global-typography-accent-font-family), sans-serif;
  flex: 0.7;
  outline: none;
  min-width: max-content;
  transition: all 0.5s;
}

.navLinkCommonStyle,
.navMobileLinkCommonStyle {
  outline: none;
  text-decoration: none;
}

.linkComponent {
  transition: all 0.5s;
}

.linkComponent:hover {
  transform: translateY(-5px);
}

.navLinkCommonStyle:hover {
  color: var(--color-green);
}

.navLinkCommonStyle:active,
.navLinkCommonStyle:focus,
.navMobileLinkCommonStyle:active,
.navMobileLinkCommonStyle:focus {
  color: var(--color-java);
  outline: none;
  text-decoration: none;
}

.navActiveLinkStyle {
  color: var(--color-java);
}

.appointmentButton {
  border: 2px solid var(--color-black);
  border-radius: 100px;
  padding: 3px 3%;
  height: 35px;
  justify-content: center;
  align-items: center;
  margin-left: 5%;
}

.mobileAppointButton {
  border: 2px solid var(--color-black);
  border-radius: 100px;
  padding: 3px 3%;
  height: 35px;
  justify-content: center;
  align-items: center;
  margin: 10px 10px;
}

.appointmentButton:hover, .mobileAppointButton:hover {
  background-color: var(--color-dark-green);
  color: var(--color-white);
  animation: var(--global-zoom-in-out) 0.5s ease;
}
