.carouselRootComponent {
    height: 90vh;
}

.stepsRootComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    transition: all 5ms;
    margin-top: 15px;
}

.stepsCommonStyle {
    height: 7px;
    border-radius: 10px;
    cursor: pointer;
}

.activeStepsStyle {
    width: 2%;
    background-color: var(--color-dark-green);
}

.inActiveStepsStyle {
    width: 1%;
    background-color: var(--color-gray);
}

@media screen and (max-width: 600px){
    .activeStepsStyle {
        width: 5%;
        background-color: var(--color-dark-green);
    }
    
    .inActiveStepsStyle {
        width: 4%;
        background-color: var(--color-gray);
    }

    .stepsRootComponent {
        margin-top: 4%;
        margin-bottom: 4%;
    }
    .carouselRootComponent {
        height: 100%;
    }
}



