.slideTwoComponent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #eef4f4;
}

.slideTwoInnerComponent {
  display: flex;
  margin: 0 auto;
  height: 100%;
  align-items: center;
}

.slideTwoTitleComponent {
  flex: 0.6;
  background-image: url("/public/images/Ornament-1-a.png");
  background-position: left left;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  margin-top: 17%;
}

.slideTwoSubIntro {
  font-size: 20px;
  color: var(--color-gray);
}

.slideTwoImageComponent {
  flex: 0.4;
}

.slideTwoTitle {
  font: 700 4rem "Fira Sans", sans-serif;
  animation: var(--global-line-swipe) 1s ease-out;
}

.slideTwoTitleSpan {
  background-image: linear-gradient(
    to right,
    var(--color-lightblue),
    var(--color-green)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.slideTwoInnerOuterComponent {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

/* .slideTwoImageInnerComponent {
    background-image: var(--slide-one-image);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 35rem;
    width: 35rem;
    animation: var(--global-zoom-in-out) 0.5s ease;
    -webkit-mask-image: url('/public/images/handc.png');
    -webkit-mask-size: contain;
    -webkit-mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    -webkit-animation: spin 10s linear infinite;
    -moz-animation: spin 10s linear infinite;
    animation: spin 10s linear infinite;
} */

.rotatingPalmImage {
  height: 35rem;
  width: 35rem;
  /* -webkit-animation: spin 20s linear infinite;
  -moz-animation: spin 20s linear infinite;
  animation: spin 20s linear infinite; */
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.handImageComponent {
  
display: flex;
 
  transform: translateX(-50%, -50%);
}
.slideTwoBtn{
  text-decoration:none;
  font: 500 30px sans-serif;
  color: black;
}

.slideTwoBtnIcon {
  rotate: 180deg;
}

.slideTwoBtnIconComponent {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font: 500 30px sans-serif;
  max-width: 10rem;
  margin-top: 10%;
  border-radius: 100px;
  background: linear-gradient(
    to right,
    var(--color-lightblue),
    var(--color-green)
  );
}

@media screen and (max-width: 700px) {

  .slideTwoTitleComponent {
    flex: 1;
  }
  .slideTwoImageComponent {
    flex: 1;
    margin: 10% 0;
  }
  .slideTwoInnerComponent {
    display: flex;
    flex-direction: column;
  }

  .slideTwoInnerOuterComponent {
    justify-content: center;
    margin-top: 0%;
  }

  .rotatingPalmImage {
    width: 90%;
    height: 100%;
  }

  .handImageComponent {
    position: absolute;
    /* left: 0; */
    left: 25%;
    top: 22%;
    transform: translateX(-50%, -50%);
  }
  .slideTwoTitle {
    font: 700 2rem "Fira Sans", sans-serif;
  }
}
