.gallery_root_component {
    margin: 0 0 3% 0;
    padding: 5px
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 10px;
}

@media screen and (max-width: 767px) {
  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 5px;
  }
}

.gallery__item_component {
  overflow: hidden;
  cursor: pointer;
}

.gallery__item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  transition: all 500ms ease-in-out;
}

.gallery__item:hover {
  transform: scale(1.05);
}
